.eula-dialog-area {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1000;

  backdrop-filter: blur(4px);
}

.eula-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 280px;
  max-width: 800px;
  width: calc(100% - 80px);
  padding: 60px 40px 40px 40px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  gap: 60px;

  /* style */
  box-shadow: 0px 0px 32px 16px rgba(0, 0, 0, 0.3);

  @media screen and (max-width: 559px) {
    width: calc(100% - 40px);
  }
}

.eula-dialog-logo {
  display: flex;
  justify-content: center;
  height: 32px;
  margin-bottom: 20px;
}

.eula-dialog-title {
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

.eula-dialog-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.eula-dialog-message {
  text-align: center;
  white-space: pre-wrap;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

iframe {
  width: 100%;
  height: 100%;
  min-height: 240px;
  max-width: 100%;
  max-height: 100%;
  background: #eee;
}

.eula-dialog-buttons {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  @media screen and (max-width: 559px) {
    flex-direction: column-reverse;
    gap: 10px;
  }
}

.eula-dialog-disagree {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.eula-dialog-disagree > button {
  display: flex;
  min-width: 128px;
  max-width: 200px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  border-radius: 5px;
  background-color: #fff;

  color: #00abe8;
  text-align: center;
  font-size: 1rem;
  line-height: 1.25rem;

  @media screen and (max-width: 559px) {
    max-width: unset;
  }
}

.eula-dialog-agree {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.eula-dialog-agree > button {
  display: flex;
  min-width: 128px;
  max-width: 200px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  border-radius: 5px;
  border: 1px solid #00abe8;
  background: #00abe8;

  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;

  @media screen and (max-width: 559px) {
    max-width: unset;
  }
}

.eula-dialog-display>.common-button {
  display: flex;
  min-width: 120px;
  max-width: 280px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 5px;

  @media screen and (max-width:559px) {
    padding: 8px;
  }
}

.eula-dialog-display .button-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.eula-dialog-cancel {
  display: block;
}

.eula-dialog-cancel>.common-button {
  display: flex;
  min-width: 120px;
  max-width: 280px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 5px;
  border: 1px solid rgba(38, 39, 44, 0.7);
  background: #fff;
  color: #26272c;
}

.eula-dialog-cancel>button-icon-left,
.eula-dialog-cancel>button-icon-right {

  @media screen and (max-width:559px) {
    width: 0;
  }
}
