.ticketRemainBar-tr {
  height: 8px;
  display: flex;
  width: 100%;
  background: var(--primary-ghost-primary-ghost-active, rgba(0, 171, 232, 0.12));
  border-radius: 8px;
}

.ticketRemainBar-remain-cell {
  border-radius: 8px;
  background: var(--primary-primary, #00abe8);
  padding: 0px;
  width: var(--remain-width);
  display: flex;
}
