.voiceTypeSettingsRow {
    width: 100%;
    display: grid;
    grid-template-columns: 100% 0% 0%;
    /* TODO Ver1.1で音声選択追加のためレイアウト修正 */
    /* grid-template-columns: 40% 40% 20%; */
}

.voiceTypeSettingsRow label{
    display: inline-flex;
    padding: 4px;
    align-items: center;
    gap: 4px;
}

@media (hover: hover) {
    .voiceTypeSettingsRow label:hover{
        border-radius: 5px;
        background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
        transition: 0.3s;
    }
}

.voiceTypeSettingsRow label input[type='checkbox']{
    width: 18px;
    height: 18px;
}

label,
input[type='checkbox'],
input[type='radio'] {
    cursor: pointer;
    align-items: center;
}

input[type='radio']{
    transform: scale(1.25);
}


.checkbox-label {
    color: var(--control-control-on-surface, #26272C);
}

.radio-label {
    padding-left: 0.25rem;
}
