.videoInfoRow-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.wrap-col {
    display: flex;
    align-items: center;
    gap: 5px;
    min-width: -webkit-fill-available;
    min-width: fit-content;
}

.video-title {
    min-width: 0%;
    text-align: left;
}

/* TODO 暫定 MUI上書き */
.video-title div {
    color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
}

.video-time {
    text-align: right;
    color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
}

.delete-icon {
    width: 44px;
    height: 44px;
    /* TODO 以下適用されていない .bt-delete img にて透過指定している */
    color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
}

.bt-delete {
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 22px;
}

.bt-delete img {
    width: 24px;
    height: 24px;
    opacity: 50%;
}

@media (hover: hover) {
    .bt-delete:hover {
        background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
        transition: 0.3s;
    }
}
.bt-delete:active {
    background: var(--control-control-active, rgba(0, 0, 0, 0.12));
    transition: 0.3s;
}
