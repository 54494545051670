.drag-drop-wrap {
  display: flex;
  width: 100%;
  max-width: 800px;
  padding: 28px 40px;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1024px) {
    min-width: calc(375px - 40px);
    padding: 24px 40px;
  }

  @media screen and (max-width: 559px) {
    min-width: unset;
    padding: 24px 20px;
  }
}

.drag-drop-zone-safari {
  display: flex;
  height: 100%;
  padding: 40px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 14px;
  border: 1px dashed var(--control-control-border, rgba(38, 39, 44, 0.7));

  @media screen and (max-width: 559px) {
    padding: 20px 0;
  }
}

@media (hover: hover) {
  .drag-drop-zone-safari:hover {
    border: 1px dashed var(--primary-primary, #00abe8);
    background: var(--primary-ghost-primary-ghost-hover, rgba(0, 171, 232, 0.05));
  }
}

.drag-drop-zone-safari.caution {
  border: 1px dashed var(--caution-caution-border, rgba(255, 59, 48, 0.8));
  background: var(--caution-caution-ghost, rgba(255, 59, 48, 0.03));
}

.drag-drop-zone-safari.drag {
  border: 1px dashed var(--primary-primary, #00abe8);
  background: var(--primary-ghost-primary-ghost-hover, rgba(0, 171, 232, 0.05));
}

.drag-drop-cont-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 250px;
  background-color: transparent;
}

.drag-drop-cont-wrap input[type="file"] {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.vertical-10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.drag-drop-image {
  display: flex;
  justify-content: center;
}

.drag-drop-image img {
  width: 96px;
  height: 96px;
  opacity: 0.1;
}

.inner-label {
  color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: normal;

  @media screen and (max-width: 559px) {
    font-size: 1.125rem;
  }
}

.inner-label-sub {
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
  text-align: center;
  font-size: 1.125rem;
  line-height: normal;

  @media screen and (max-width: 559px) {
    font-size: 0.875rem;
  }
}

.inner-label-info p {
  color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
}
