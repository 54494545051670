.settings-lang-none {
    display: none;
}

.settings-lang-label {
    display: flex;
    padding: 4px 20px;
    align-items: center;

    border-radius: 28px;
    background: var(--tag-cell, rgba(0, 0, 0, 0.05));

    overflow: hidden;
    color: var(--control-control-on-surface, #26272C);
    text-align: center;
    text-overflow: ellipsis;

    /* 12 */
    font-size: 0.75rem;
    line-height: 24px;

    pointer-events: none;
}

.settings-lang-label-block {
    display: flex;
    padding: 4px 20px;
    align-items: center;

    border-radius: 28px;
    background: var(--tag-cell, rgba(0, 0, 0, 0.05));

    overflow: hidden;
    color: var(--control-control-on-surface, #26272C);
    text-align: center;
    text-overflow: ellipsis;

    /* 12 */
    font-size: 0.75rem;
    line-height: 24px; /* TBD 変数化 アイコンと同じ高さ */

    pointer-events: none;
}

.settings-lang-button-block {
    display: inline-flex;
    padding: 4px 8px 4px 24px;
    align-items: center;

    border-radius: 28px;
    background: var(--tag-cell, rgba(0, 0, 0, 0.05));
}
    @media (hover: hover) {
        .settings-lang-button-block:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), var(--tag-cell, rgba(0, 0, 0, 0.05));
            transition: 0.3s;
        }
    }
    .settings-lang-button-block:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), var(--tag-cell, rgba(0, 0, 0, 0.05));
        transition: 0.3s;
    }


.settings-lang-row {
    display: flex;
    width: 100%;
    min-width: 0;
    flex-wrap: wrap;
    gap:4px;
}


.settings-lang-button-lebel {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    overflow: hidden;
    color: var(--control-control-on-surface, #26272C);
    text-align: center;
    text-overflow: ellipsis;

    /* 12 */
    font-size: 0.75rem;
    line-height: 24px; /* TBD 変数化 アイコンと同じ高さ */
}
.settings-lang-button-open {
    transform: rotate(0deg);
    transition: 0.3s;
}

.settings-lang-button-close {
    transform: rotate(180deg);
    transition: 0.3s;
}
