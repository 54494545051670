.translate-from-row {
  display: flex;
  padding: 8px 10px 8px 10px;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  align-self: stretch;
  width: 100%;

  @media screen and (max-width:559px) {
    gap: 4px;
  }
}

/* TODO 暫定 MUI上書き */
.translate-from-row.bg-red-200 {
  background: var(--caution-caution-hover, rgba(255, 59, 48, 0.03));
}

.translate-from-row-col1 {
  display: flex;
  align-items: stretch;
  min-width: 96px;
  max-width: 96px;
  min-height: 54px;
  border-radius: 5px;
  background: var(--util-op-20, rgba(0, 0, 0, .2));
}

.translate-from-row-col1>button>img {
  border-radius: 5px;
}

.translate-from-row-col2 {
  flex-grow: 1;
  min-width: 0;
}

.translate-from-row-col2 p,
.translate-from-row-col2 div {
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-align: left;

  @media screen and (max-width:559px) {
    font-size: 0.75rem;
  }
}

.translate-from-row-col2 p {
  line-height: 1.25rem;
}

.translate-from-row-col2 span:first-child {
  color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
}

.translate-from-row-col2 span:nth-of-type(2) {
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
}

.translate-from-row-col3 {}

.translate-from-row-col3 button {
  min-width: 120px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 7px 8px;
  gap: 4px;
  border-radius: 5px;

  @media screen and (max-width: 559px) {
    padding: 10px;
    min-width: 44px;
    border-radius: 50%;
  }
}

@media (hover: hover) {
  .translate-from-row-col3 button:hover {
    background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
    transition: 0.3s;
  }
}

.translate-from-row-col3 button:active {
  background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
  transition: 0.3s;
}


.translate-from-row-col3 .text-button-label {
  display: flex;
  flex-direction: column;
  padding: 0;

  @media screen and (max-width:559px) {
    display: none;
  }
}

.translate-from-row-col3 span {
  color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1;
  white-space: nowrap;
}

.translate-from-row-col4 {}

/* deleteボタン */

.translate-from-row-col4 button {
  min-width: max-content;
  display: inline-flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  min-width: -webkit-max-content;
  min-height: 44px;
  border-radius: 5px;

  @media screen and (max-width: 1024px) {
    min-width: 44px;
    padding: 10px;
    border-radius: 50%;
    gap: 0;
  }

}

.translate-from-row-col4 span {
  color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  white-space: nowrap;
}

@media (hover: hover) {
  .translate-from-row-col4 button:hover {
    background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
    transition: 0.3s;
  }
}

.translate-from-row-col4 button:active {
  background: var(--control-control-active, rgba(0, 0, 0, 0.12));
  transition: 0.3s;
}


.translate-from-row-col4 .text-button-label {
  display: flex;
  flex-direction: column;
  padding: 0;

  @media screen and (max-width:1024px) {
    display: none;
  }
}

.translate-from-row-col4 .text-button-label span {
  font-size: 0.75rem;
  line-height: 1;
  white-space: nowrap;
}


.translate-from-row-col5 {}

.translate-from-row-col5 button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

@media (hover: hover) {
  .translate-from-row-col5 button:hover {
    background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
    transition: 0.3s;
  }
}

.translate-from-row-col5 button>img {
  width: 24px;
  height: 24px;
}

.translate-from-row-menu-open {
  transform: rotate(0deg);
  transition: 0.6s;
}

.translate-from-row-menu-close {
  transform: rotate(180deg);
  transition: 0.6s;
}

/* TODO 暫定 */
.flex {
  display: flex;
  gap: 4px !important;
  align-items: center;
  hyphens: auto;

  @media screen and (max-width:1024px) {
    gap: 0 !important;
  }
}

.MuiPaper-root {
  width: 100%;
}

.MuiPaper-root>video {
  width: 100%;
}

.upload-download-button button:disabled {
  opacity: 0.5;
  border: 1px solid rgba(38, 39, 44, .5);
  pointer-events: none;
}

.upload-download-button {
  position: relative;
}

.upload-downloading {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.75));
}
