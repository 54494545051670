.subtitlesInfo-area {
  display: flex;
  padding: 30px;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.fileInfo-from-row {
  display: flex;
  flex-direction: row;
}

.subtitlesInfo-title {
  width: 40%;
  vertical-align: top;
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
  font-size: 0.875rem;

  @media screen and (max-width: 1024px){
    width: 50%;
  }
}

.subtitlesInfo-title p{
  @media screen and (max-width: 559px) {
    font-size: 0.75rem;
  }
}

.subtitlesInfo-value {
  width: 60%;

  @media screen and (max-width: 1024px){
    width: 50%;
  }
}

.subtitlesInfo-value p {
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
  font-size: 0.875rem;

  @media screen and (max-width: 559px) {
    font-size: 0.75rem;
  }
}

.subtitlesInfo-area-upper {
  display: flex;
  flex-direction: column;
  gap: 0;
  overflow-x: hidden;
}

.subtitlesInfo-area-upper img {
  border-radius: 10px 10px 0 0;
  stroke-width: 1px;
  stroke: var(--control-separator, rgba(0, 0, 0, 0.12));
}

.subtitlesInfo-area-upper p {
  max-width: 100%;
  min-height: 20px;
  color: var(--card-card-on-surface, #26272C);
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px; /* 125% */
}


.subtitlesInfo-area-bottom {
  display: flex;
  flex-direction: column;
}

.subtitlesInfo-movie-length {
  display: flex;
  padding: 8px 24px;
  gap: 4px;
  border: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
  border-radius: 0 0 10px 10px;
}

.subtitlesInfo-movie-length p {
  color: var(--card-card-on-surface, #26272C);
}
