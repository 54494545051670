.step-indicator {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

/* ラベル */
.step-indicator li {
    position: relative;
    list-style-type: none;
    text-align: center;
    min-width: 9.5em;
    color: rgba(38, 39, 44, 1);
    font-size: 0.8125rem;
    margin: 10px 0;

    @media screen and (max-width: 559px) {
        min-width: 6.5em; /* TODO 暫定 spはdesignを変える */
        margin: 0;
    }
}
.step-indicator li.active,
.step-indicator li.complete {
    color: rgba(38, 39, 44, 1);
}

/* インジケータ*/
.step-indicator li:before {
    width: 22px;
    height: 22px;
    display: block;
    box-sizing: border-box;
    margin: 4px auto 0.25rem auto;
    border: 2px solid rgba(229, 229, 229, 1);
    border-radius: 50%;
    background-color: rgba(204, 204, 204, 1);
    content: '';
}
/* インジケータ.current */
.step-indicator li.active:before {
    width: 22px;
    height: 22px;
    background-color: rgba(0, 171, 232, 1);
    border-color: rgba(255,255,255,1);
    content: ''
}

/* インジケータ.done */
.step-indicator li.complete:before {
    width: 22px;
    height: 22px;
    background-color: rgba(0, 171, 232, 1);
    background-image: url(../../../img/check-inner-rect.svg);
    background-size: contain;
    vertical-align: middle;
}

/* ライン */

.step-indicator li:after {
    position: absolute;
    z-index: -1;
    top: calc(((24px + (3px * 2)) - 2px) / 2); /*サークルの半分 - 線の太さの半分*/
    left: -50%;
    width: 100%;
    height: 2px;
    content: '';
    background-color: rgba(204, 204, 204, 1);
}
.step-indicator li:first-child:after {
    content: none;
}
.step-indicator li.active:after,
.step-indicator li.complete:after {
    background-color: rgba(0, 171, 232, 0.6);
}
