.subtitle-row {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    border-radius: 14px;
    border: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
    background: rgba(0, 0, 0, 0.03);
}

.subtitle-row:focus-within {
    background: var(--primary-ghost-primary-ghost-hover, rgba(0, 171, 232, 0.05));
}

.subtitle-row1 {
    display: flex;
    align-items: stretch;
    gap: 4px;

    @media screen and (max-width:559px) {
        flex-direction: column;
    }
}

.subtitle-row-col1-group {
    display: flex;
    flex-direction: column;
    width: 60%;
    max-width: 60%;
    gap: 10px;

    @media screen and (max-width:559px) {
        width: 100%;
        max-width: 100%;
    }
}

.subtitle-row-col1 {
    height: 100%
}

.subtitle-row-col1 div {
    height: inherit;
    display: flex;
    padding: 12px;
    align-items: flex-start;
    flex-grow: 0.3;

    border-radius: 4px;
    border: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
    background: var(--card-card, #FFF);
    transition: background 0.3s ease;

    font-size: 0.875rem;

    overflow-y: hidden;
    width: 100%;
}

.subtitle-row-col1 textarea {
    width: 100%;
    height: 100%;
    min-height: 64px;
    display: flex;
    padding: 12px;
    align-items: flex-start;

    border-radius: 4px;
    border: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
    background: var(--card-card, #FFF);
    transition: background 0.3s ease;

    font-size: 0.875rem;
    resize: none;
}

.subtitle-row-col1 textarea:disabled {
    width: 100%;
    height: 100%;
    min-height: 64px;
    display: flex;
    padding: 12px;
    align-items: flex-start;

    border-radius: 4px;
    border: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
    background: transparent;
    border-color: transparent;

    font-size: 0.875rem;
    resize: none;
}

.subtitle-row-col1 .error {
    border: 1px solid var(--input-error-border, rgba(153, 8, 0, 0.30));
    background: var(--input-error, #FFEFEE) !important;
    color: var(--input-error-on-surface, #990800) !important;
}

.editor-translation-button div{
    vertical-align: bottom;
}

.editor-translation-button {
    text-align: -webkit-right;
}

.subtitle-row-col2-group {
    display: flex;
    flex-direction: column;
    width: 60%;
    max-width: 60%;
    gap: 16px;

    @media screen and (max-width:559px) {
        width: 100%;
        max-width: 100%;
    }
}

.subtitle-row-detail {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.subtitle-row1-col div {
    height: inherit;
    display: flex;
    padding: 12px;
    align-items: flex-start;
    flex-grow: 0.3;

    border-radius: 4px;
    border: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
    background: var(--card-card, #FFF);
    transition: background 0.3s ease;

    font-size: 0.875rem;

    overflow-y: hidden;
    width: 100%;
}

.subtitle-row1-col textarea {
    width: 100%;
    height: inherit;
    min-height: 82px;
    display: flex;
    padding: 12px;
    align-items: flex-start;

    border-radius: 4px;
    border: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
    background: var(--card-card, #FFF);
    transition: background 0.3s ease;

    font-size: 0.875rem;
    resize: none;
}

.subtitle-row1-col .error {
    border: 1px solid var(--input-error-border, rgba(153, 8, 0, 0.30));
    background: var(--input-error, #FFEFEE) !important;
    color: var(--input-error-on-surface, #990800) !important;
}


.subtitle-row2 {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    @media screen and (max-width: 1024px) {
        justify-content: flex-end;
    }
}

.subtitle-row2-col {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    width: max-content;
}

.subtitle-row2-col input[type='text'] {
    border-radius: 4px;
    border: 1px solid transparent;
    max-width: calc(10.5ch + 12px);
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.60));
    text-align: center;
    padding: 4px 4px;
}

.subtitle-row2-col input[type='text']:hover {

@media (hover: hover) {
    border: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
    }
}

.subtitle-row2-col .error {
    border: 1px solid var(--input-error-border, rgba(153, 8, 0, 0.30));
    background: var(--input-error, #FFEFEE) !important;
    color: var(--input-error-on-surface, #990800) !important;
}

.subtitle-row2-col p {
    color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
    font-size: 0.875rem;
}

.subtitle-row2-col-mid {
    opacity: 0.4;

    @media screen and (max-width: 1024px) {
        display: none;
      }
}

.subtitle-row2-col img {
    @media screen and (max-width: 1024px) {
        display: none;
      }
}

.subtitle-row-group {
    width: 100%;

    display: flex;
    align-items: stretch;
    gap: 20px;

    @media screen and (max-width:559px) {
        flex-direction: column;
    }
}

.translate {
  display: flex;
  min-width: 160px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #fff;

  border-radius: 22px;
  border: 1px solid #00abe8;
  background: #00abe8;

  transition: filter 0.3s, background 0.3s;
  height: 40px
}

.translate:hover {
  @media (hover: hover) {
    filter: brightness(1.1);
    transition: 0.3s;
  }
}

.translate:active {
  filter: brightness(0.8);
  transition: 0.3s;
}

.translate:disabled {
  background: rgba(0, 0, 0, 0.12);
  border-color:transparent;
  color: rgba(0, 0, 0, 0.28);
}
