.common-button {
  display: flex;
  min-width: 160px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #fff;

  border-radius: 22px;
  border: 1px solid #00abe8;
  background: #00abe8;

  transition: filter 0.3s, background 0.3s;
}

.common-button:hover {
  @media (hover: hover) {
    filter: brightness(1.1);
    transition: 0.3s;
  }
}

.common-button:active {
  filter: brightness(0.8);
  transition: 0.3s;
}

.button-contents {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.button-label {
  padding:0;

  @media screen and (max-width:559px) {
    font-size: 0.875rem;
  }
}

.button-icon-left {
  width: 24px;
  height: 24px;
}

.button-icon-right {
  width: 24px;
  height: 24px;
}

.translation-add-file-button button {
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #00abe8;
  font-size: 14px;
  font-weight: 700;
  border-radius: 5px;
}

@media (hover: hover) {
  .translation-add-file-button button:hover {
    background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
    transition: 0.3s;
  }
}

.translation-add-file-button button:active {
  background: var(--control-control-active, rgba(0, 0, 0, 0.12));
  transition: 0.3s;
}

.translation-add-file-button button:disabled {
  color: var(--primary-primary-on-surface-disabled, rgba(0, 0, 0, 0.28));
  pointer-events: none;
}
