.account-menu-button {
  display: block;
  width: 32px;
  height: 32px;

  @media screen and (max-width:559px) {
    width: 28px;
    height: 28px;
  }
}

.account-menu-button img {
  width: 32px;
  height: 32px;

  @media screen and (max-width:559px) {
    width: 28px;
    height: 28px;
  }

}
