.voice-type-settings-dialog-area {
  position: fixed;
  z-index: 1700;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.voice-type-settings {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 280px;
  max-width: 800px;
  width: calc(100% - 40px);
  max-height: 90dvh;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  border-radius: 14px;
  background-color: #fff;



  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.3);

  @media screen and (max-width: 559px) {
    padding: 16px;
  }
}

.voice-type-settings-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.voice-type-settings-header {
  display: flex;
  padding-left: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.voiceTypeSettingsDialog-main {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  gap: 10px 10px;
  overflow-y: overlay;
  height: 300px;
  width: 100%;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  text-align: center;
  padding-left: 54px;
}

.title>p {
  font-size: 2rem;
  line-height: 40px;
  text-align: center;
  font-weight: 400;

  @media screen and (max-width: 559px) {
    font-size: 1.25rem;
  }
}



.description-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  hyphens: auto;
  width: 100%;

  @media screen and (max-width: 559px) {
    gap:10px;
  }

}

.description-secondary p {
  font-size: 0.75rem;
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));

  @media screen and (max-width: 559px) {
    gap:0;
  }


}

.voice-type-settings-close {

}

.voice-type-settings-close button {
  width: 44px;
  height: 44px;
  padding: 10px;
  border-radius: 50%;
}

@media (hover: hover) {
  .voice-type-settings-close button:hover {
    background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
    transition: 0.3s;
  }
}

.voice-type-settings-close button:active {
  background: var(--control-control-active, rgba(0, 0, 0, 0.12));
  transition: 0.3s;
}

.voice-type-row {
  display: flex;
  width: 100%
}

.voice-type-left {
  text-align: left;
  /*TODO 暫定*/
  flex: calc(50% - 5px);
}

.voice-type-right {
  /* text-align: right; */
  /* TODO Ver1.1で音声選択追加のためレイアウト修正 */
  text-align: left;
  /*TODO 暫定*/
  flex: calc(50% - 5px);
}

.setting-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  overflow-y: hidden;
}

.setting-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.select-lang-label-info p {
  color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
}

.select-lang-label-alert p {
  color: var(--caution-caution, rgba(255, 59, 48, 0.80));
}

.searchLang {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.textbox {
  display: flex;
  width: 60%;
  min-width: 200px;
  padding: 6px 12px;
  align-items: flex-start;
  gap: 10px;

  border-radius: 5px;
  border: 1px solid var(--control-control-border, rgba(38, 39, 44, 0.60));
}
