.drag-drop-wrap {
  display: flex;
  width: 100%;
  max-width: 800px;
  padding: 28px 40px;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1024px) {
    min-width: calc(375px - 40px);
    padding: 24px 40px;
  }

  @media screen and (max-width: 559px) {
    min-width: unset;
    padding: 24px 20px;
  }
}

.drag-drop-zone {
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 14px;
  border: 1px dashed var(--control-control-border, rgba(38, 39, 44, 0.7));

  @media screen and (max-width: 559px) {
    padding: 20px 0;
  }
}

@media (hover: hover) {
  .drag-drop-zone:hover {
    border: 1px dashed var(--primary-primary, #00abe8);
    background: var(--primary-ghost-primary-ghost-hover, rgba(0, 171, 232, 0.05));
  }
}

.drag-drop-zone.caution {
  border: 1px dashed var(--caution-caution-border, rgba(255, 59, 48, 0.8));
  background: var(--caution-caution-ghost, rgba(255, 59, 48, 0.03));
}

.drag-drop-zone.drag {
  border: 1px dashed var(--primary-primary, #00abe8);
  background: var(--primary-ghost-primary-ghost-hover, rgba(0, 171, 232, 0.05));
}

.drag-drop-cont-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  background-color: transparent;
}

.vertical-10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.drag-drop-image {
  display: flex;
  justify-content: center;
}

.drag-drop-image img {
  width: 96px;
  height: 96px;
  opacity: 0.1;
}

.inner-label {
  color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: normal;

  @media screen and (max-width: 559px) {
    font-size: 1.125rem;
  }
}

.inner-label-sub {
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
  text-align: center;
  font-size: 1.125rem;
  line-height: normal;

  @media screen and (max-width: 559px) {
    font-size: 0.875rem;
  }
}

.inner-label-info p {
  color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
}

.mvs-upload {
  display: flex;
  min-width: 160px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #fff;

  border-radius: 22px;
  border: 1px solid #00abe8;
  background: #00abe8;

  transition: filter 0.3s, background 0.3s;
}

.mvs-upload:hover {
  @media (hover: hover) {
    filter: brightness(1.1);
    transition: 0.3s;
  }
}

.mvs-upload:active {
  filter: brightness(0.8);
  transition: 0.3s;
}
