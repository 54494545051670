.license-dialog-area {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2000;
}

.license-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  min-width: 640px;
  max-width: 800px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: #fff;

  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.3);
}

.license-dialog-header {
  display: flex;
  align-items: center;
}

.license-dialog-title {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.7);
}

.dialog-close-button {
  width: 44px;
  height: 44px;
  position: absolute;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

@media (hover: hover) {
  .dialog-close-button:hover {
    background: rgba(0, 0, 0, 0.05);
    transition: 0.3s;
  }
}

.dialog-close-button:active {
  background: rgba(0, 0, 0, 0.12);
  transition: 0.3s;
}

.dialog-close-icon {
  height: 24px;
  width: 24px;

  mask: url('../../../img/close.svg') no-repeat center center / contain;
  -webkit-mask: url('../../../img/close.svg') no-repeat center center / contain;
  background: #000;
}

.license-list-container {
  height: 480px;
  overflow-y: auto;
  overflow-wrap: break-word;
}

.license-list-cell-container:not(:last-child) {
  margin-bottom: 1rem;
}

/* スクロールバーの設定 */
.license-list-container::-webkit-scrollbar {
  width: 5px;
}

.license-list-container::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.5);
}

.license-list-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 6px;
}
