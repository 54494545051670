.common-dialog-area {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 2000;
}

.common-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 280px;
  max-width: 640px;
  width: calc(100% - 80px);
  padding: 30px;
  border-radius: 14px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  gap: 40px;

  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.3);

  @media screen and (max-width:559px) {
    width: calc(100% - 40px);
  }
}

.dialog-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.common-dialog-title {
  color: #26272c;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}

.common-dialog-message {
  float: left;
  white-space: pre-wrap;
}

.common-dialog-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
  align-self: stretch;

  @media screen and (max-width:559px) {
    justify-content: center;
  }
}

.cancel-button>.common-button {
  display: flex;
  min-width: 120px;
  max-width: 280px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 5px;
  border: 1px solid rgba(38, 39, 44, 0.7);
  background: #fff;
  color: #26272c;
}

.cancel-button>button-icon-left,
.cancel-button>button-icon-right {

  @media screen and (max-width:559px) {
    width: 0;
  }
}

.dialog-button-primary>.common-button {
  display: flex;
  min-width: 120px;
  max-width: 280px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 5px;

  @media screen and (max-width:559px) {
    padding: 8px;
  }
}

.dialog-button-primary .button-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.dialog-button-destructive>.common-button {
  background: #fff;
  text-overflow: ellipsis;
  border-radius: 5px;
  border: 1px solid var(--caution-caution-border, rgba(255, 59, 48, 0.80));

  @media screen and (max-width:559px) {
    padding: 8px;
  }
}

.dialog-button-destructive .button-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: var(--caution-caution, rgba(255, 59, 48, 0.80));
}

.common-dialog-buttons .button-label {
  padding: 0;
}

.confirm-dialog-button-secondary {
  display: block;
}


.confirm-dialog-button-secondary>.common-button {

  background-color: transparent;

  @media screen and (max-width:559px) {
    padding: 8px;
  }
}

.confirm-dialog-button-secondary>.common-button:hover {

  @media (hover: hover) {
    background-color: var(--control-control-hover, rgba(0, 0, 0, 0.05));
  }
}

.confirm-dialog-button-secondary>.common-button:active {
  background-color: var(--control-control-active, rgba(0, 0, 0, 0.12));
}

.alert-dialog-button-secondary {
  display: none;
}
