.translationInfoArea-main {
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.translationInfoArea-left {
  flex: 70%;
}

.translationInfoArea-left.mod-desc {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.translationInfoArea-left.mod-desc p:nth-child(2){
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
  font-size: 0.8125rem;
}

.translationInfoArea-right {
  flex: 30%;
}

.translationInfoArea-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

._top-border {
  border-top: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
}
