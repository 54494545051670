.translate-to-row {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 0 8px 30px;
}
.translate-to-row-col1 {
  position: relative;
  min-width: 96px;
  max-width: 96px;
  min-height: 54px;
  border-radius: 5px;
  background: var(--util-op-20,rgba(0,0,0,.2));
}

.translate-to-row-col1>img {
  width: 96px;
  height: auto;
  border-radius: 5px;
}

.translate-to-row-col2 {
  display: flex;
  width: fit-content;
  white-space: nowrap;
}

.translate-to-row-col3, .translate-to-row-col3 div {
  min-width: 0;
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
  font-size: 0.875rem;
}

.flex {
  display: flex;
}

.upload-lang-label {
  display: inline-flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 28px;
  border: 1px solid var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
  font-size: 0.75rem;
  line-height: 1.5rem;
  white-space: nowrap;
  pointer-events: none;
}

.play-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.75));
}

@media (hover: hover) {
  .play-button button:hover {
    filter: drop-shadow(0px 0px 4px rgba(0, 171, 232, 0.50));
    transition: 0.3s;
  }
}

.mvs-dialog-agree>.common-button {
  display: flex;
  min-width: 120px;
  max-width: 280px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 5px;

  @media screen and (max-width:559px) {
    padding: 8px;
  }
}

.mvs-dialog-agree .button-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
