.ticket-usage-area-main {
  width: 100%;
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.ticket-usage-area-main .flex-col {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.usage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  gap: 8px;
}
.usage span {
  font-weight: 700;
  font-size: 1.6875rem;
  line-height: 1;
  font-weight: bold;
}
.usage-title {
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
}
.usage-value {
  color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
}

.remain-value-black {
  color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
  text-align: center;
}

.remain-value-red {
  color: var(--caution-caution, rgba(255, 59, 48, 0.8));
  text-align: center;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  padding: 20px;
  max-width: calc(375px - 40px);

  @media screen and (max-width:1024px){
    display: none;
  }
}
