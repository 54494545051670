.ticket-remain-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding: 6px 8px 6px 16px;
    width: max-content;
    border-radius: 5px;
    pointer-events: none;
    /* border: 1px solid var(--control-control-border, rgba(38, 39, 44, 0.7)); */

    @media screen and (max-width: 1024px){
        display: none;
    }

}
@media (hover: hover) {
    .ticket-remain-button:hover {
        background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
        transition: 0.3s;
    }
}
.ticket-remain-button:active {
    background: var(--control-control-active, rgba(0, 0, 0, 0.12));
    transition: 0.3s;
}


.mvs-buy2 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--control-control-on-surface, rgba(38, 39, 44, 1));
}

.ticket-info {
    display: flex;
    gap: 4px;
}
/* チケットの残り時間 */
.mvs-buy1 {
    text-align: center;
}

.mvs-buy1 p {
    color: var(--control-control-on-surface, rgba(38, 39, 44, 1));
    font-size: 0.875rem;
    line-height: 1rem;
    white-space: nowrap;
}

/* 最も短い有効期限チケットの期限日 */
.expiration-date {
    color: var(--control-control-on-surface, rgba(38, 39, 44, 1));
    text-align: center;
    font-size: 0.75rem;
    line-height: 1rem;
}
/* 購入ラベル */
.ticket-remain-button-label {
    color: var(--control-control-on-surface, rgba(38, 39, 44, 1));
    text-align: center;
    font-size: 0.75rem;
    line-height: 1rem;
    white-space: nowrap;
}
