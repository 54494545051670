* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
}

body {
  background-color: rgb(229, 229, 229);
}

.app {
  height: 100dvh;
  min-width: 375px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.common-area {
  max-height: 100%;
  padding: 10px 40px 0 40px;
  display: flex;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 10px 0px 0px 0px;
  }
}

.common-area-left {
  min-width: 240px;
  @media screen and (max-width: 1024px) {
    display: none;
    min-width: 0;
  }
}

.common-area-center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.common-area-right {
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 240px;
  @media screen and (max-width: 1024px) {
    display: none;
    min-width: 0;
  }
}

.content {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  padding-bottom: calc(40px + env(safe-area-inset-bottom));
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  overflow-y: visible;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: visible;
    padding-bottom: calc(40px + 78px + env(safe-area-inset-bottom));
  }

  @media screen and (max-width: 559px) {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: calc(20px + 78px + env(safe-area-inset-bottom));
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  height: 100%;
  min-height: 640px;
  /* overflow-y: scroll; */

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.content-header {
  /* layout */
  display: flex;
  padding: 16px 0px 12px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 10px;
  /* Style */
  border-bottom: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
}

.content-header > h2,
.content-header > p {
  /* Typography */
  color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  white-space: nowrap
}

.content-header-desc-wrap {
  display: flex;
  flex-direction: column;
  hyphens: auto;
}

.content-header-desc-wrap a {
  font-size: 0.8125rem;
  line-height: 1.25;
  color: rgba(0, 171, 232, 1);
  white-space: nowrap;
}

.content-header-desc-wrap.row {
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.content-header-desc {
  font-size: 0.8125rem;
  line-height: 1.25;
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));

  @media screen and (max-width: 1024px) {
    font-size: 0.75rem;
  }
}

.content-header.w-button {
  padding: 12px 0px 8px 0px;
}

.content-left {
  min-width: 0;
  display: flex;
  padding: 0px 20px 20px 20px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  position: relative;
  min-width: calc(375px - 40px);

  @media screen and (max-width: 1024px) {
    align-self: flex-start;
    width: 100%;
  }
}

/* settings用 */
.content-right {
  display: flex;
  min-width: calc(375px - 40px);
  /* 40pxはSP時の左右のmargin合計 */
  padding: 0px 20px 20px 20px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
}

.style-card {
  border-radius: 14px;
  background: var(--card-dialog-card, #fff);

  /* elevate-2 */
  box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.12);
}

.controls {
  /*Layout*/
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  /* Style */
  border-radius: 0px 0px 14px 14px;
  background: var(--view-theme-w-filter, rgba(255, 255, 255, 0.5));
  /* ControlContainer */
  backdrop-filter: saturate(2) blur(8px);
}

.footer {
  display: flex;
  padding: 20px 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  min-width: 375px;

  z-index: 1300;
  backdrop-filter: saturate(2) blur(8px);
  background-color: rgba(255, 255, 255, 0.3);

  overscroll-behavior-y: none;

  @media screen and (max-width: 1024px) {
    position: fixed;
    bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
  }
}

.footer-left {
  width: 100%;

  @media screen and (max-width: 559px) {
    width: 0;
  }
}

.footer-center {
  width: 100%;
}

.footer-right {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.thumbnail-base {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 54px;
  background: var(--util-op-20, rgba(0, 0, 0, 0.2));
  border-radius: 5px;
}

.thumbnail-base.mod-error {
  background: var(--caution-caution-secondary, #f2d4d2);
}

div:has(.common-button):focus-visible {
  outline: 0;
}

/* snackbar */
.notistack-MuiContent-error {
  color: #990800 !important;
  border: 1px solid var(--snackbar-error-border, rgba(153, 8, 0, 0.3)) !important;
  background-color: var(--snackbar-error, #ffefee) !important;
}

.notistack-MuiContent-warning {
  color: #997a00 !important;
  border: 1px solid var(--snackbar-warning-border, rgba(153, 122, 0, 0.3)) !important;
  background-color: var(--snackbar-warning, #fff9e0) !important;
}

.notistack-MuiContent-success {
  color: #207936 !important;
  border: 1px solid var(--snackbar-success-border, rgba(32, 121, 54, 0.3)) !important;
  background-color: var(--snackbar-success, #e7f8eb) !important;
}

.notistack-MuiContent {
  border-radius: 5px !important;
  padding: 0px 16px !important;
}

.notistack-MuiContent div {
  font-size: 0.875rem !important;
  margin-right: 0px !important;
}
