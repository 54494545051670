.account-menu-dialog {
  margin-right: 0;
  margin-left: auto;
  border-radius: 50%;
}

.account-menu {
  position: absolute;
  right: 10px;
  z-index: 2500;
  top: 60px;

  display: flex;
  max-width: 400px;
  min-width: 280px;
  flex-direction: column;
  align-items: flex-end;
}

.account-menu-dialog summary {
  outline: none;
  border: 1px solid transparent;
  border-radius: 50%;
}

.account-menu-dialog summary:focus-visible {
  border: 1px solid var(--primary-primary, #00abe8);
}


.account-menu-dialog summary::-webkit-details-marker {
  display: none;
}

.account-menu-dialog summary {
  list-style: none;
}

details[open]>summary::before {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  display: block;
  cursor: default;
  content: ' ';
  background: transparent;

  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(4px);
}

/* ↓ ヘッダー */
.account-menu-header {
  /* Layout */
  display: flex;
  padding: 20px 20px 10px 20px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  /* Style */
  border-radius: 14px 14px 0px 0px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), var(--card-card, #FFF);
}

/*TODO ちゃんと設定*/
.account-menu-header img {
  width: 32px;
  height: 32px;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(308deg) brightness(99%) contrast(101%);
}

.account-menu-icon {
  text-align: left;
}

.account-menu-icon>img {
  width: 32px;
  height: 32px;
}

.account-info-wrap {
  width: calc(100% - 24px - 20px);
  /*TODO エリプシスが表示されないので暫定*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
}

.account-menu-id {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: var(--card-card-on-surface, #26272C);
  font-size: 12px;
  line-height: 12px;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
}

.account-menu-id * {
  /* TODO 暫定 */
  font-size: 0.875rem;
}

.account-menu-plan {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: var(--card-card-on-surface, #26272C);
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: normal;
  font-style: normal;
  font-weight: 400;
  /* 100% */
}

/* ↑ ヘッダーここまで */

.account-menu-content {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding-top: 10px;

  /* Style */
  border-radius: 0px 0px 14px 14px;
  background: var(--card-card, #fff);
}

.account-menu-ticket {
  display: flex;
  padding: 0 10px 10px 10px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

/* .account-menu-ticket>div:first-child {
  border: 1px solid black;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
} */

/* .account-menu-ticket>div:last-child {
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-radius: 0px 0px 5px 5px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), var(--card-card, #fff);
  padding: 5px;
} */

.account-menu-remain {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: 3px 3px 0px 0px;
  border: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
}

.account-menu-remain-label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: var(--card-card-on-surface, #26272C);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  /* 100% */
}

.account-menu-remain-ticket {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: var(--card-card-on-surface, #26272C);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.account-menu-expiration-ticket {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 0px 0px 3px 3px;
  border-right: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
  border-bottom: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
  border-left: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
  background: var(--control-control__hover, rgba(0, 0, 0, 0.05));
}

.account-menu-expiration-ticket-label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: var(--card-card-on-surface, #26272C);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 0.75rem;
}

 .mvs-menu-profile, .mvs-menu-help, .mvs-menu-feedback, .mvs-menu-logout {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.account-menu-inner-wrap {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.account-menu-item {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.account-menu-text {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
  width: 100%;

  /* Typography */
  color: var(--control-control-on-surface, #26272C);
  font-size: 14px;
  line-height: 1;
}

@media (hover: hover) {
  .account-menu-item:hover {
    background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
    transition: 0.3s;
  }
}

.account-menu-item:active {
  background: var(--control-control-active, rgba(0, 0, 0, 0.12));
  transition: 0.3s;
}

.account-menu-item.mod-end {
  margin-bottom: 5px;
}

.menu-footer-wrap {
  display: flex;
  padding: 12px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  border-radius: 0px 0px 14px 14px;
  border-top: 1px solid var(--control-separator, rgba(0, 0, 0, 0.12));
  background: var(--card-card, #FFF);
}

.l-horizontal {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.menu-footerlink {
  display: flex;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 5px;
}

@media (hover: hover) {
  .menu-footerlink:hover {
    background: var(--util-op-8, rgba(0, 0, 0, 0.08));
    transition: 0.3s;
  }
}

.menu-footerlink:active {
  background: var(--util-op-20, rgba(0, 0, 0, 0.2));
  transition: 0.3s;
}

.menu-footerlink a,
button {
  color: var(--control-control-on-surface, rgba(38, 39, 44, 1));
  text-align: center;
  font-size: 0.75rem;
  line-height: 0.875;
}
