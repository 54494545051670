/* 参照先 TranslateFromRow.css */

.translation-table-header-row {
  padding: 8px 10px;
  display: table-row;
  backdrop-filter: saturate(2) blur(8px);
  background: rgba(255,255,255,.8);
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
}

.outside-row {
  border-bottom: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28));
}

/* @media (hover: hover) {
  .outside-row:hover {
    background-color: var(--control-control-hover, rgba(0, 0, 0, 0.05));
  }
}

.outside-row:active {
  background-color: var(--control-control-active, rgba(0, 0, 0, 0.12));
} */

/* TODO 暫定 TW上書き */
.translate-row.bg-red-200 {
  background-color: var(--caution-caution-hover, rgba(255, 59, 48, 0.03));
}

.translate-row.bg-gray-200 {
  background-color: var(--util-op-5, rgba(0, 0, 0, 0.05));
}

.thumbnail-base>img {
  border-radius: 5px;
}

.thumbnail-container {
  position:relative;
  /* display: inline-block; */
}

.video-duration {
  position:absolute;
  bottom: 0px;
  right: 0px;
  padding: 0px 4px;

  border-radius: 0px 0px 5px 0px;
  background: var(--body-body-on-surface, rgba(38, 39, 44, 0.80));
}

.video-duration p {
  color: white;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
}

.row-container {
  display: flex;
  flex-direction: row;
}

.file-container {
  padding: 4px 0 4px 20px;
  overflow: hidden;
}
.file-container div {
  font-size: 0.875rem;
}
.file-container p {
  font-size: 0.875rem;
}

.download-button-label {
  font-size: 0.8125rem;

  @media screen and (max-width:1024px) {
    display: none;
  }
}

.translation-lang-label {
  display: flex;
  min-width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 0;
  border-radius: 24px;
  white-space: nowrap;

  @media screen and (max-width:559px) {
    white-space: initial;
  }
}

.mod-ghost {
  padding: 0 8px;
  border: 1px solid var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));

  @media screen and (max-width:1024px) {
    height: 24px;
  }
}

/* TODO 暫定 imgはmaskで設定 */
.mod-ghost img {
  opacity: 0.4;

  @media screen and (max-width:1024px) {
    display: none;
  }
}

.mod-ghost .flex div:first-child {

  @media screen and (max-width:1024px) {
    display: none;
  }
}

.mod-filled {
  padding: 4px 8px;
  background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
  font-size: 0.75rem;
}

.translation-lang-font-size {
  font-size: 0.75rem;
}

.translation-download-button {
  position: relative;
}

.translation-download-button button {
  padding: 8px;
  border-radius: 5px;

  @media screen and (max-width:1024px) {
    border-radius: 50%;
  }
}

.translation-download-button button:disabled {
  opacity: 0.28;
  border: none;
  pointer-events: none;
  background: var(--control-fill-disabled, rgba(0, 0, 0, 0.12));
}

@media (hover: hover) {
  .translation-download-button button:hover {
    background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
    transition: 0.3s;
  }
  .translation-download-button button:active {
    background: var(--control-control-active, rgba(0, 0, 0, 0.12));
    transition: 0.3s;
  }
}

.translation-downloading {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.8));
}

/* 最後の１行を-1で被せていない分細くする  */
.translation-translation-list-row:last-of-type .MuiBox-root .MuiBox-root .MuiLinearProgress-root {
	height: 3px;
}

.btn-subtitle-edit {

  display: flex;
  padding: 6px 6px 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--primary-primary, #00ABE8);

  appearance: none;
  cursor: pointer;



  transition: all 150ms ease-in-out 0s;
}

.btn-subtitle-edit .button-label {
  color: var(--primary-primary, #00ABE8);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
}

.btn-subtitle-edit .button-icon-right {
  width: 20px;
  height: 20px;
}


.btn-subtitle-edit .button-icon-left {
  width: 0;
  height: 20px;
}

.btn-subtitle-edit:disabled {
  background: var(--primary-primary__disabled, rgba(0, 0, 0, 0.12));
  border-color: transparent;
  pointer-events: none;
  }

  .btn-subtitle-edit:disabled .button-label {
    color: var(--primary-primary-on-surface__disabled, rgba(0, 0, 0, 0.18));
  }

  .btn-subtitle-edit:disabled .button-icon-right {
    opacity: 0.4;
  }

  @media (hover: hover){
    .btn-subtitle-edit:hover {
      border-radius: 5px;
      border: var(--Shadow-shadow-14, 1px) solid var(--primary-primary, #00ABE8);
      background: var(--primary-ghost-primary-ghost__hover, rgba(0, 171, 232, 0.05));
  }
}
  .btn-subtitle-edit:active {
    border-radius: 5px;
    border: var(--Shadow-shadow-14, 1px) solid var(--primary-primary, #00ABE8);
    background: var(--primary-ghost-primary-ghost__active, rgba(0, 171, 232, 0.12));
  }

  .btn-subtitle-edit .button-contents div:disabled {
    color: var(--primary-primary__disabled, rgba(0, 0, 0, 0.12));
  }

.common-button input[type="file"], .btn-subtitle-edit input[type="file"] {
  display: none;
}

.btn-download {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--primary-primary, #00ABE8);
  transition: all 150ms ease-in-out 0s;
}

.btn-download:hover:enabled {
@media (hover: hover) {
  filter: brightness(1.1);
  transition: 0.3s;
  border-color: #00bfff;
}
}

.btn-download:disabled {
background: var(--primary-primary__disabled, rgba(0, 0, 0, 0.12));
pointer-events: none;
}

.btn-download .button-icon-right,.btn-download .button-icon-left {
  width: 0;
  height: 20px;
}

.btn-download .button-label{
  color: var(--primary-primary-on-surface, #FFF);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: normal;
}
.btn-download:disabled .button-label{
  color: var(--primary-primary-on-surface__disabled, rgba(0, 0, 0, 0.18));
  }

.translation-thead {
  /* display: flex; */
  width: 100%;
  /* border-bottom: 1px solid var(--card-card-border, rgba(0, 0, 0, 0.28)); */
  display: table-header-group;
}

.tbl-span {
  /* max-width: 20%;
  min-width: 20%; */
  /* display: flex;
  align-items: center;
  align-self: stretch; */
  display: table-cell;
  width: 1px;
  white-space: nowrap;
}

.tbl-header-file-name {
  padding: 8px 136px;
  max-width: 0;

  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.60));
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: start;
  white-space: nowrap;
}

.tbl-file-name {
  display: table-cell;
  padding: 8px 8px 8px 20px;
  max-width: 0;
}

.tbl-target-lang {
  text-align: left;
  padding: 8px;
  width: 1px;
  white-space: nowrap;

  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.60));
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.tbl-exp-date {
  /* max-width: 10%;
  min-width: 10%; */
  /* padding: 0 20px 0 10px;
  text-align: left;
  white-space: nowrap;
  display: flex;
  align-items: center;
  align-self: stretch;
  overflow: hidden;*/
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.60));
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* display: table-cell; */
  padding: 8px;
  width: 1px;
  white-space: nowrap;
}

.tbl-exp-date > label {
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.60));
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}


.tbl-del-btn{
  /* max-width: 2%;
  min-width: 2%; */
  /* align-items: center;
  align-self: stretch;
  display: flex;
  text-align: start;
  display: table-cell; */
  padding: 8px;
  width: 1px;
  white-space: nowrap;
}

.tbl-edit-subtitle-btn{
  /* max-width: 10%;
  min-width: 10%; */
  /* align-items: center;
  align-self: stretch;
  display: flex;
  display: table-cell; */
  padding: 8px;
  width: 1px;
  white-space: nowrap;
}



.tbl-download-btn{
  /* max-width: 12%;
  min-width: 12%; */
  /* align-items: center;
  align-self: stretch;
  display: flex;
  display: table-cell; */
  padding: 8px 20px 8px 8px;
  width: 1px;
  white-space: nowrap;
}

.btn_icon {
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 20px;
}

@media (hover: hover){
  .btn_icon:hover{
    background: var(--control-control__hover, rgba(0, 0, 0, 0.05));
  }
}
.btn_icon.disabled {
  pointer-events: none;
  opacity: 0.18;
  filter: saturate(0.0);
}

.btn_icon .button-icon-right {
  height: 20px;
  width: 20px;
  mask: url('../../../img/chevron_right.svg') no-repeat center center / contain;
  -webkit-mask: url('../../../img/chevron_right.svg') no-repeat center center / contain;
  background: #fff;
  color:  #00abe8;
}


.subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* max-width: 700px;
  min-width: 300px; */

  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.60));
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;

  @media screen and (max-width:1024px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.translate-from-row {
  padding-bottom: '0px';
}

.progress-row {
  display: flex;
  align-items:  center;
  gap: 10px;
  width: 100%;
  flex-direction: row;
}

.progress-col {
  /* width: 100%; */
  /* padding: 0px 8px; */
}

.progress {
  width: auto;
}

.progress div {
  /* プログレスの％表示を非表示 */
  margin: 0;
  min-width: 0;
}

/* プログレスの％表示を非表示 */
.progress p {
  display: none;
}
