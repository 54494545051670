.settings-area-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  overflow-y: hidden;

  @media screen and (max-width: 1024px){
    overflow-y: visible;
  }
}

.settings-area-row {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.settings-area-row.mod-translate-to{
  min-height: 32px; /*最小1行*/
  overflow-y: hidden;

  @media screen and (max-width: 1024px){
    overflow-y: visible;
  }

}

.mod-translate-from, .mod-subtitle {
  height: fit-content;
}

.settings-area-lang {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 4px;
  flex: 1 0 0;
  flex-wrap: wrap;
  height: 100%;
  overflow-y: overlay;

  @media screen and (max-width: 1024px){
    overflow-y: visible;
  }
}

.title-cell {
  vertical-align: top;
}

.settings-label {
  float: left;
}

.edit-button {
  width: 100%;
  float: right;
}

.settings-area-block {
  flex: 55%;
  display: flex;
  flex-wrap: wrap;
  /* text-align: left; */
  /* TODO：調整用 */
}


.settings-area-title {
  display: flex;
  min-width: 128px;
  align-items: flex-start;
  align-self: stretch;

}
.settings-area-title p{
  /* TODO 暫定 */
  font-size: 0.875rem;
  color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
}

.settings-area-title.mod-align-center {
  align-items: center;
}

.settings-area-value {
  flex: 55%;
  display: flex;
  flex-wrap: wrap;
}

.settings-area-value p {
  font-size: 0.875rem;
  color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
}
