

.header {
  display: flex;
  min-height: 54px;
  padding-left: 20px;
  padding-right: 40px;
  padding-top: calc(env(safe-area-inset-top,0));
  padding-bottom: 0;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-bottom: 1px solid var(--control-border-header, rgba(255, 255, 255, 0.44));
  background: var(--theme-theme, #26272c);

  @media screen and (max-width: 559px) {
    height: 44px;
    min-height: 44px;
    padding: 0px 20px 0px 20px;
  }
}

.header-left {
  width: auto;
  display: flex;
  align-items: center;
}

.header-home-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  border-radius: 5px;
  padding: 4px 8px;
}

.header-home-button:hover{
  @media (hover: hover) {
    background-color: rgba(255,255,255,.12);
    transition: 0.3s;
  }
}

.header-home-button:active{
  background-color: rgba(255,255,255,.18);
  transition: 0.3s;
}

.header-logo {
  height: 26px;
  width: auto;

  @media screen and (max-width: 559px) {
    height: 26px;
  }
}
.product-name {
  font-size: 1rem;
  font-weight: 700;
  color:#ffffff;

  @media screen and (max-width: 559px) {
    display: none;
  }
}

.header-center {
  width: auto;

  @media screen and (max-width: 559px) {
    display: none;
  }
}

.header-right {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.header-right select {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  background-color: #26272c;
  background-image: url('../../img/language.svg'), url('../../img/arrow_drop_down_white.svg');
  background-position: center left, center right;
  background-repeat: no-repeat, no-repeat;
  color: #fff;
  font-size: 12px;
  line-height: normal;
  font-style: normal;
  font-weight: 400;
  padding: 14px 28px;
  border: 1px solid transparent;
}

.header-right select:focus-visible{
  border: 1px solid var(--primary-primary, #00abe8);
}

.header-right option {
  font-size: 12px;
  color: #fff;
}

.mvs-dialog-agree>.common-button {
  display: flex;
  min-width: 120px;
  max-width: 280px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 5px;

  @media screen and (max-width:559px) {
    padding: 8px;
  }
}

.mvs-dialog-agree .button-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
