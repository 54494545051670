.videoInfo-area-main {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
}

.videoInfo-area-row {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.videoInfo-area-row-voicetype{
    display: none;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.videoInfo-area-title {
    display: flex;
    vertical-align: top;
}

.videoInfo-area-title p {
    color: var(--card-card-on-surface-secondary, rgba(38, 39, 44, 0.7));
    font-size: 0.875rem;
}

.videoInfo-area-value {
    display: flex;
    flex-wrap: wrap;
    margin-left: 45px;
}

.videoInfo-area-value p {
    color: var(--card-card-on-surface, rgba(38, 39, 44, 1));
    font-size: 0.875rem;
    line-height: 1em;
}
