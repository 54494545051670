.video-preview-dialog-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.65);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 1700;

  display: flex;
  align-items: center;
  justify-content: center;
}

.video-preview-dialog {
  max-width: 900px;
  max-height: 90vh;
  margin: auto;
  position: relative;
  border-radius: 14px;

  background-color: #FFF;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.3);
}

.close-button {
  width: 100%;
  padding: 16px 24px;
}

.close-button button {
  float: right;
  width: 44px;
  height: 44px;
  padding: 10px;
  border-radius: 50%;
  margin-bottom: 12px;
}

@media (hover: hover) {
  .close-button button:hover {
    background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
    transition: 0.3s;
  }
}

.close-button button:active {
  background: var(--control-control-active, rgba(0, 0, 0, 0.12));
  transition: 0.3s;
}

.video-preview-container {
  padding: 0 24px 24px 24px;
}

@media screen and (max-width: 559px) {
  .close-button {
    padding: 0 8px;
  }

  .video-preview-container {
    padding: 0 16px 16px 16px;
  }
}
