label,
input[type='radio'] {
    cursor: pointer;
    align-items: center;
}

.radio-group {
    display: flex;
    min-width: 152px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding: 6px;
    border-radius: 5px;
}

@media (hover: hover) {
    .radio-group:hover {
        background: var(--control-control-hover, rgba(0, 0, 0, 0.05));
        transition: 0.3s;
    }
}
.label-wrap {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 2px;
}

.radio-label {
    padding-left: 0.25rem;
}

.radio-img {
    vertical-align: middle;
}
